<template>
  <div class="row">
    <div class="col-md-12 ">
      <div class="card form-body">
        <div class="card-body">
          <h6>Training Links</h6>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <editor
                  api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                  v-model="link"
                  :init="config"
                />
                <span v-if="errors['link']" class="text-danger">{{
                  errors["link"][0]
                }}</span>
              </div>
            </div>
          </div>

          <!-- 
          <h4 @click="checkTwilioKey()">Checkme</h4> -->
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_training_link_submit')"
            style="float: right"
            ref="kt_training_link_submit"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_TRAINING_LINK,
  SUBMIT_TRAINING_LINK
} from "@/core/services/store/actions.type";
import Editor from "@tinymce/tinymce-vue";
export default {
  data() {
    return {
      link: "",
      id: 0,
      errors: [],
      config: {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt"
      }
    };
  },
  components: {
    editor: Editor
  },
  created() {
    this.$store.dispatch(FETCH_TRAINING_LINK).then(data => {
      var data_ = data.data;

      this.link = data_.link ? data_.link : "";
      this.id = data_.id ? data_.id : 0;
    });
  },

  methods: {
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    update(ref) {
      this.errors = [];

      var data = {
        link: this.link,
        id_: this.id
      };

      this.loadingButton(ref);
      this.$store
        .dispatch(SUBMIT_TRAINING_LINK, data)
        .then(data => {
          this.id = data.id;
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
        })
        .catch(err => {
          this.errors = err.msg;
          this.closeBtnLoad(ref);
        });
    }
  }
  // checkTwilioKey() {
  //   const client = require("twilio")(this.account_sid, this.auth_token);
  //   client.newKeys.create({friendlyName: 'User Joey'})
  //           .then(new_key => console.log(new_key.sid));
  // },

  //   onSubmit() {
  //     this.errors = [];
  //     const submitButton = this.$refs["kt_training_link_submit"];
  //     submitButton.disabled = true;
  //     submitButton.classList.add("spinner", "spinner-light", "spinner-right");
  //     var data = {
  //       link: this.link,
  //       id_: this.id
  //     };

  //     this.$store
  //       .dispatch(SUBMIT_TRAINING_LINK, data)
  //       .then(data => {
  //         submitButton.classList.remove(
  //           "spinner",
  //           "spinner-light",
  //           "spinner-right"
  //         );
  //         submitButton.disabled = false;
  //         this.$toastr.s(data.msg);
  //       })
  //       .catch(err => {
  //         this.errors = err.msg;
  //         submitButton.classList.remove(
  //           "spinner",
  //           "spinner-light",
  //           "spinner-right"
  //         );
  //         submitButton.disabled = false;
  //         // this.$toastr.e(err.msg);
  //       });
  //   }
};
</script>
